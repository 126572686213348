"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionType = void 0;
var SubscriptionType;
(function (SubscriptionType) {
    SubscriptionType["billable"] = "billable";
    SubscriptionType["manual"] = "manual";
    SubscriptionType["PAYFAST"] = "payfast";
    SubscriptionType["EFT"] = "eft";
    SubscriptionType["PAYGATE"] = "paygate";
})(SubscriptionType = exports.SubscriptionType || (exports.SubscriptionType = {}));
