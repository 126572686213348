"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = void 0;
class Client {
    constructor(source, key) {
        if (source) {
            Object.assign(this, source);
        }
        if (key) {
            this.$key = key;
        }
    }
    get fullName() {
        return this.firstName + ' ' + this.lastName;
    }
    get companyOrFullName() {
        if (this.companyName) {
            return this.companyName;
        }
        else {
            return this.fullName;
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$subscriptions;
        delete clone.$payments;
        delete clone.$paymentMethods;
        delete clone.$occupants;
        delete clone.$invoices;
        const client = JSON.parse(JSON.stringify(clone));
        client.createdAt = this.createdAt;
        client.updatedAt = this.updatedAt;
        return client;
    }
}
exports.Client = Client;
