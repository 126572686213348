"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadUser = exports.LeadStatus = exports.PaymentMethodType = exports.ChangeLogsEntityEnum = exports.AlertSeverity = exports.SlackChannel = void 0;
var SlackChannel;
(function (SlackChannel) {
    SlackChannel["DEVELOPMENT"] = "development";
    SlackChannel["SYSTEM"] = "system";
    SlackChannel["CRITICAL"] = "critical_alerts";
})(SlackChannel = exports.SlackChannel || (exports.SlackChannel = {}));
var AlertSeverity;
(function (AlertSeverity) {
    AlertSeverity["CRITICAL"] = "critical";
    AlertSeverity["MAJOR"] = "major";
    AlertSeverity["MINOR"] = "minor";
    AlertSeverity["WARNING"] = "warning";
    AlertSeverity["INFO"] = "info";
})(AlertSeverity = exports.AlertSeverity || (exports.AlertSeverity = {}));
var ChangeLogsEntityEnum;
(function (ChangeLogsEntityEnum) {
    ChangeLogsEntityEnum["INVOICE"] = "invoice";
    ChangeLogsEntityEnum["CLIENT"] = "client";
})(ChangeLogsEntityEnum = exports.ChangeLogsEntityEnum || (exports.ChangeLogsEntityEnum = {}));
var PaymentMethodType;
(function (PaymentMethodType) {
    PaymentMethodType["PAYGATE"] = "paygate";
    PaymentMethodType["PAYFAST"] = "payfast";
    PaymentMethodType["EFT"] = "eft";
})(PaymentMethodType = exports.PaymentMethodType || (exports.PaymentMethodType = {}));
var LeadStatus;
(function (LeadStatus) {
    LeadStatus["CONTACTED"] = "Contacted";
    LeadStatus["INTERESTED"] = "Interested";
    LeadStatus["CONVERTED"] = "Converted";
    LeadStatus["NOT_INTERESTED"] = "Not Interested";
    LeadStatus["COLD"] = "Cold";
})(LeadStatus = exports.LeadStatus || (exports.LeadStatus = {}));
var LeadUser;
(function (LeadUser) {
    LeadUser["KWANELE"] = "Kwanele";
    LeadUser["UMAR"] = "Umar";
})(LeadUser = exports.LeadUser || (exports.LeadUser = {}));
