"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayoutStatusEnum = exports.Payout = void 0;
class Payout {
    constructor(source, key) {
        this.status = PayoutStatusEnum.DUE;
        this.invoiceRefs = [];
        if (source) {
            Object.assign(this, source);
        }
        if (key) {
            this.$key = key;
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$client;
        delete clone.$merchant;
        delete clone.$parking;
        const output = JSON.parse(JSON.stringify(clone));
        if (this.invoiceRefs) {
            output.invoiceRefs = this.invoiceRefs;
        }
        if (this.merchantRef) {
            output.merchantRef = this.merchantRef;
        }
        if (this.payoutBatchRef) {
            output.payoutBatchRef = this.payoutBatchRef;
        }
        output.createdAt = this.createdAt;
        output.updatedAt = this.updatedAt;
        return output;
    }
}
exports.Payout = Payout;
var PayoutStatusEnum;
(function (PayoutStatusEnum) {
    PayoutStatusEnum["DUE"] = "due";
    PayoutStatusEnum["BATCHED"] = "batched";
    PayoutStatusEnum["PAID"] = "paid";
})(PayoutStatusEnum = exports.PayoutStatusEnum || (exports.PayoutStatusEnum = {}));
