"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentOnInvoiceEnum = exports.PayoutFrequencyEnum = exports.BankDetails = void 0;
class BankDetails {
    constructor(source) {
        this.payoutFrequency = PayoutFrequencyEnum.IMMEDIATE;
        this.paymentOnInvoice = PaymentOnInvoiceEnum.NONE;
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        return JSON.parse(JSON.stringify(clone));
    }
}
exports.BankDetails = BankDetails;
var PayoutFrequencyEnum;
(function (PayoutFrequencyEnum) {
    PayoutFrequencyEnum["IMMEDIATE"] = "IMMEDIATE";
    PayoutFrequencyEnum["MONTHLY"] = "MONTHLY";
})(PayoutFrequencyEnum = exports.PayoutFrequencyEnum || (exports.PayoutFrequencyEnum = {}));
var PaymentOnInvoiceEnum;
(function (PaymentOnInvoiceEnum) {
    PaymentOnInvoiceEnum["NONE"] = "NONE";
    PaymentOnInvoiceEnum["INVOICE_REQUIRED"] = "INVOICE_REQUIRED";
})(PaymentOnInvoiceEnum = exports.PaymentOnInvoiceEnum || (exports.PaymentOnInvoiceEnum = {}));
