"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayoutBatchStatusEnum = exports.PayoutBatch = void 0;
class PayoutBatch {
    constructor(source, key) {
        this.status = PayoutBatchStatusEnum.DUE;
        this.payoutRefs = [];
        this.merchantRefs = [];
        this.invoiceRefs = [];
        if (source) {
            Object.assign(this, source);
        }
        if (key) {
            this.$key = key;
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$client;
        delete clone.$merchant;
        delete clone.$parking;
        const output = JSON.parse(JSON.stringify(clone));
        if (this.payoutRefs) {
            output.payoutRefs = this.payoutRefs;
        }
        if (this.merchantRefs) {
            output.merchantRefs = this.merchantRefs;
        }
        if (this.invoiceRefs) {
            output.invoiceRefs = this.invoiceRefs;
        }
        output.createdAt = this.createdAt;
        output.updatedAt = this.updatedAt;
        if (this.payoutDate) {
            output.payoutDate = this.payoutDate;
        }
        return output;
    }
}
exports.PayoutBatch = PayoutBatch;
var PayoutBatchStatusEnum;
(function (PayoutBatchStatusEnum) {
    PayoutBatchStatusEnum["DUE"] = "due";
    PayoutBatchStatusEnum["PAID"] = "paid";
})(PayoutBatchStatusEnum = exports.PayoutBatchStatusEnum || (exports.PayoutBatchStatusEnum = {}));
