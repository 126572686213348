"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumNames = void 0;
const bank_details_model_1 = require("../models/bank-details.model");
const subscription_type_enum_1 = require("../models/enums/subscription-type.enum");
class EnumNames {
    static getPayoutFrequency(enumValue) {
        switch (enumValue) {
            case bank_details_model_1.PayoutFrequencyEnum.MONTHLY:
                return 'Monthly on the 1st business day';
            case bank_details_model_1.PayoutFrequencyEnum.IMMEDIATE:
                return '1 - 3 business days after client payment';
        }
    }
    static getPaymentOnInvoice(enumValue) {
        switch (enumValue) {
            case bank_details_model_1.PaymentOnInvoiceEnum.NONE:
                return 'ParkUpp makes the payout on generation of the proforma';
            case bank_details_model_1.PaymentOnInvoiceEnum.INVOICE_REQUIRED:
                return 'ParkUpp waits for invoice before making the payout';
        }
    }
    static getAdminPaymentOnInvoice(enumValue) {
        switch (enumValue) {
            case bank_details_model_1.PaymentOnInvoiceEnum.NONE:
                return 'Immediate Payout';
            case bank_details_model_1.PaymentOnInvoiceEnum.INVOICE_REQUIRED:
                return 'Invoice Required';
        }
    }
    static getClassPaymentOnInvoice(enumValue) {
        switch (enumValue) {
            case bank_details_model_1.PaymentOnInvoiceEnum.NONE:
                return 'badge bg-success text-light';
            case bank_details_model_1.PaymentOnInvoiceEnum.INVOICE_REQUIRED:
                return 'badge bg-danger text-light';
        }
    }
    static getSubscriptionType(enumValue) {
        switch (enumValue) {
            case subscription_type_enum_1.SubscriptionType.manual:
                return 'Manual (old)';
            case subscription_type_enum_1.SubscriptionType.PAYGATE:
                return 'PayGate';
            case subscription_type_enum_1.SubscriptionType.PAYFAST:
                return 'PayFast';
            case subscription_type_enum_1.SubscriptionType.EFT:
                return 'EFT';
            default:
                return '';
        }
    }
    static getSubscriptionTypeClass(enumValue) {
        switch (enumValue) {
            case subscription_type_enum_1.SubscriptionType.manual:
                return 'badge bg-dark';
            case subscription_type_enum_1.SubscriptionType.PAYGATE:
                return 'badge bg-paygate';
            case subscription_type_enum_1.SubscriptionType.PAYFAST:
                return 'badge bg-payfast';
            case subscription_type_enum_1.SubscriptionType.EFT:
                return 'badge bg-secondary';
            default:
                return '';
        }
    }
}
exports.EnumNames = EnumNames;
