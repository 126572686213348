"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerchantUserPrivate = void 0;
class MerchantUserPrivate {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        const client = JSON.parse(JSON.stringify(clone));
        client.createdAt = this.createdAt;
        // client.merchantRoles = this.merchantRoles.map((role) => ({
        //     merchantRef: this.merchantRoles.find((r) => r.merchantRef === role.merchantRef)?.merchantRef,
        //     roles: role.roles,
        // }))
        if (this.clientRef) {
            client.clientRef = this.clientRef;
        }
        if (this.merchantRef) {
            client.merchantRef = this.merchantRef;
        }
        return client;
    }
}
exports.MerchantUserPrivate = MerchantUserPrivate;
